/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// COLORS THEMES

// DANGER
:root {
	--ion-color-danger: #c62828;
	--ion-color-danger-rgb: 198,40,40;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ae2323;
	--ion-color-danger-tint: #cc3e3e;
}

.ion-color-danger {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

// PRIMARY
// :root {
// 	--ion-color-primary: #1b92b2;
// 	--ion-color-primary-rgb: 0,102,255;
// 	--ion-color-primary-contrast: #ffffff;
// 	--ion-color-primary-contrast-rgb: 255,255,255;
// 	--ion-color-primary-shade: #005ae0;
// 	--ion-color-primary-tint: #1a75ff;
	// --border-color: #dee3ea;
// }
// .ion-color-primary {
// 	--ion-color-base: var(--ion-color-primary);
// 	--ion-color-base-rgb: var(--ion-color-primary-rgb);
// 	--ion-color-contrast: var(--ion-color-primary-contrast);
// 	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
// 	--ion-color-shade: var(--ion-color-primary-shade);
// 	--ion-color-tint: var(--ion-color-primary-tint);
// }
// :root {
// 	--ion-color-primary: #1b92b2 !important;
// 	--ion-color-primary-rgb: 27,146,178 !important;
// 	--ion-color-primary-contrast: #ffffff !important;
// 	--ion-color-primary-contrast-rgb: 255,255,255 !important;
// 	--ion-color-primary-shade: #18809d !important;
//     --ion-color-primary-tint: #329dba !important;
//     --border-color: #dee3ea;
// }

// .ion-color-primary {
// 	--ion-color-base: var(--ion-color-primary);
// 	--ion-color-base-rgb: var(--ion-color-primary-rgb);
// 	--ion-color-contrast: var(--ion-color-primary-contrast);
// 	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
// 	--ion-color-shade: var(--ion-color-primary-shade);
// 	--ion-color-tint: var(--ion-color-primary-tint);
// }
// :root {
// 	--ion-color-primary: #43b6d6;
// 	--ion-color-primary-rgb: 42,136,243;
// 	--ion-color-primary-contrast: #ffffff;
// 	--ion-color-primary-contrast-rgb: 255,255,255;
// 	--ion-color-primary-shade: #2578d6;
//     --ion-color-primary-tint: #3f94f4;
//     --border-color: #f0ebee; //#dee3ea;
// }

// .ion-color-primary {
// 	--ion-color-base: var(--ion-color-primary);
// 	--ion-color-base-rgb: var(--ion-color-primary-rgb);
// 	--ion-color-contrast: var(--ion-color-primary-contrast);
// 	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
// 	--ion-color-shade: var(--ion-color-primary-shade);
// 	--ion-color-tint: var(--ion-color-primary-tint);
// }

// PRIMARY
:root {
	--ion-color-primary: #080b2d;
	--ion-color-primary-rgb: 8,11,45;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #070a28;
	--ion-color-primary-tint: #212342;
	--ion-card-background: #080b2d !important;	
	--border-color: #f0ebee;
}

.ion-color-primary {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
    --border-color: #f0ebee;
}

// DARK MODE 
body.dark {
	--ion-color-primary: #43b6d6;
	--ion-color-primary-rgb: 67,182,214;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #3ba0bc;
	--ion-color-primary-tint: #56bdda;
	--ion-background-color: #1f2123 !important;
	--ion-card-background: #34373a !important;
    --border-color: #262626 !important;
    
	.input {
		&:focus {
			box-shadow: 0px 0px 0px 3px rgba(#43b6d6, .3) !important;
		}
	}
	.light-text {
		color: #737373 !important;
	}
	.box-shadow {
		box-shadow: inset 0 0px 0px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(0 0 0 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important;
    }
    .toolbar-merge-background {
        --ion-toolbar-background: #1f2123 !important;
    }
}
@media (prefers-color-scheme: dark) {
    :root {
        --ion-color-primary: #43b6d6;
        --ion-color-primary-rgb: 67,182,214;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0,0,0;
        --ion-color-primary-shade: #3ba0bc;
        --ion-color-primary-tint: #56bdda;
        --ion-background-color: #1f2123 !important;
        --ion-card-background: #34373a !important;
        --border-color: #262626 !important;
	}
	body {
        --ion-color-primary: #43b6d6;
        --ion-color-primary-rgb: 67,182,214;
        --ion-color-primary-contrast: #000000;
        --ion-color-primary-contrast-rgb: 0,0,0;
        --ion-color-primary-shade: #3ba0bc;
        --ion-color-primary-tint: #56bdda;
        --ion-background-color: #1f2123 !important;
        --ion-card-background: #34373a !important;
        --border-color: #262626 !important;
    }
    .toolbar-merge-background {
        --ion-toolbar-background: #1f2123 !important;
    }
	.input {
		&:focus {
			box-shadow: 0px 0px 0px 3px rgba(#43b6d6, .3) !important;
		}
	}
	.light-text {
		color: #737373 !important;
	}
	.box-shadow {
		box-shadow: inset 0 0px 0px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(0 0 0 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%) !important;
	}
    .chip-primary {
        background-color: rgba(#43b6d6, 0.2) !important;
        color: var(--ion-color-primary) !important;
    }
    .chip-success {
        background-color: rgba(#2dd36f, 0.2) !important;
        color: var(--ion-color-success) !important;
    }
    .chip-warn {
        background-color: rgba(#ffc409, 0.2) !important;
        color: var(--ion-color-warning) !important;
    }
    .chip-danger {
        background-color: rgba(#c62828, 0.2) !important;
        color: var(--ion-color-danger) !important;
    }
    .chip-suggestion {
        background-color: rgba(#ff6600, 0.2) !important;
        color: #ff6600 !important;
    }
    .chip-note {
        background-color: rgba(117, 65, 26, 0.2) !important;
        color: rgb(117, 65, 26) !important;
    }
    .chip-question {
        background-color: rgba(112, 87, 255, 0.2) !important;
        color: rgb(112, 87, 255) !important;
    }
}
// END COLOR THEMES


// TEXT
:root {
    --ion-text-color: #262626;
}
body {
    --ion-text-color: #262626;
}
.toolbar-merge-background {
    --ion-toolbar-background: #fff;
}
.primary-text {
	color: var(--ion-color-primary) !important
}
.text-color {
    color: var(--ion-text-color) !important;
}
.light-text {
	color: #505f73;
}

// END TEXT

// IMAGE CLASSES
.logo-small {
	height: 32px;
	width: 32px;
    object-fit: contain;
}


// CUSTOM COLORS
$background-color: var(--ion-background-color);
$border-color: var(--border-color);
.letter-color {
	color: var(--ion-color-primary) !important
}
// END CUSTOM COLORS



// CUSTOM ION CLASSES
ion-title {
    font-weight: 400;
    color: #505f73;
	cursor: pointer;
}
ion-button {
	margin: 0;
	--ripple-color: transparent;
	--box-shadow: none;
	--background-hover-opacity: 0;
	&:focus {
		outline: none;
	}
}
ion-card {
	&:focus {
		outline: none;
	}
}
// END CUSTOM ION CLASSES



// SCROLLBAR CLASSES
.hide-scrollbar::-webkit-scrollbar {
    display: none !important;
}
/* For Firefox and IE */
.hide-scrollbar {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
}
// END SCROLLBAR CLASSES



// FLEX CLASSES
.spacer {
	flex: 12
}
.flex-col {
	display: flex;
	flex-direction: column;
}
.flex-row {
	display: flex;
	flex-direction: row;
}
.flex-start {
    justify-content: start;
}
.flex-start-start {
    justify-content: start;
    align-content: start;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-center-center {
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}
.flex-center-horizontal {
	justify-content: center
}
.flex-center-vertical {
	align-items: center
}
.flex-space-evenly {
    justify-content: space-evenly;
}
.flex-align-center {
	align-items: center
}
.flex-align-baseline {
	align-items: baseline
}
.flex-align-end {
	align-items: flex-end
}
.entity-flex-col-md-down {
    display: grid;
    grid-template-columns: 225px auto;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px 20px 0 20px;
    margin-top: 20px;
    height: 100% !important;
    section {
        // border: 2px solid green;
        &:first-child {
            height: fit-content !important;
        }
        &:last-child {
            position: relative;
            height: 100% !important;
        }
    }
}
// END FLEX CLASSES



// GRID CLASSES 

.grid-col-2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(47%, 1fr));
    gap: 15px;
    align-content: center;
}
.grid-col-3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-gap: 15px;
    align-content: center;
}
.grid-col-4 {
	width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    gap: 15px;
    align-content: center;
}
// END GRID CLASSES



// CUSTOM CSS CLASSES
p,
strong {
	font-size: 14px;
	margin-top: 0px;
    line-height: 1.5;
	&:last-child {
		margin-bottom: 0px
	}
}
p {
    font-weight: 500;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.width-100 {
	width: 100%
}
.height-100 {
	height: 100%
}
.gap-5 {
	gap: 5px;
}
.gap-10 {
	gap: 10px;
}
.gap-15 {
	gap: 15px;
}
.gap-20 {
	gap: 20px;
}
.gap-30 {
	gap: 30px;
}
.container {
    padding: 20px;
    width: 100%
}
.container-border {
	border: 1px solid $border-color;
	border-radius: 6px;
}
.container-create {
    width: 100%;
    display: grid;
	grid-template-columns: auto 225px;
	gap: 20px;
}
.container-danger {
	width: 100%;
    border: 1px solid var(--ion-color-danger) !important;
    border-radius: 6px !important;
}
.container-gap {
    gap: 30px;
}
.container-padding {
	padding: 20px;
}
.input-item {
    display: flex;
    flex-direction: column;
}
.input-label {
    font-size: 13.5px;
    font-weight: 600;
    margin-bottom: 6px;
    color: var(--ion-text-color);
    line-height: 1.5
}
.input-label-large {
    font-size: 15.5px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--ion-text-color)
}
.survey-label {
    font-size: 16px
}
.hint {
    font-size: 12px;
    color: #949494
}
.description {
    color: #949494
}
.label-disabled {
    color: #949494
}
.input {
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-text-color);
    margin-bottom: 20px;
    &:focus {
        outline-offset: 0px !important;
        outline: none !important;
        border-color: var(--ion-color-primary) !important;
        box-shadow: 0px 0px 0px 3px rgba(#080b2d, .3);
    }
}
.input-danger, 
.input-danger:focus {
    border-color: var(--ion-color-danger) !important;
    box-shadow: 0px 0px 0px 3px rgba(#c62828, .3) !important;
}
.input-note-required {
    color: var(--ion-color-danger);
    font-size: 13.5px;
    height: 16px;
    line-height: 1.2;
    margin: 6px 0px 0px 0px
}
textarea {
	resize: none;
	height: 200px;
}
.textarea-editable {
    background: none;
    border: none;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    outline-offset: 0px;
    outline: none;
    border: 1px solid #43b6d6;
    box-shadow: 0px 0px 0px 3px rgba(#43b6d6, .3);
}
select {
    &:hover {
        cursor: pointer
    }
}
.select {
    position: relative;

}
.select::after {
    position: absolute;
    top: 11px;
    right: 16px;
	color: rgb(117, 117, 117);
    font-size: 12px;
    content: '▼';
    pointer-events: none;
}
.placeholder::before {
    position: absolute;
    top: 9px;
    left: 12px;
    color: rgb(117, 117, 117);
    font-size: 14px;
    content: attr(data-before);
    pointer-events: none;
}
.select-pseudo {
	color: rgb(117, 117, 117);
    cursor: pointer;
    font-weight: 400;
}
.dropdown {
	color: rgb(117, 117, 117);
	font-size: 12px;
	cursor: pointer;	
}
.box-shadow {
    box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}
.no-border {
    box-shadow: none !important;
    border: none !important;
}
.border {
    border: 1px solid $border-color !important;
    box-shadow: none !important;
    border-radius: 6px !important;
}
.border-primary {
    border: 1px solid var(--ion-color-primary) !important;
}
.border-primary-dark-mode {
    border: 1px solid #43b6d6 !important;
}
.border-danger {
    border: 1px solid var(--ion-color-danger) !important;
}
.border-color {
    border: 1px solid $border-color !important;
}
.border-popover {
    border: 1px solid $border-color !important;
    border-radius: 4px !important;
}
.border-top {
    border-top: 1px solid $border-color !important;
}
.border-bottom {
    border-bottom: 1px solid $border-color !important;
}
.pointer {
	cursor: pointer !important
}
.fit-content {
    width: fit-content;
    height: fit-content;
    margin: 0
}
.danger {
    background-color: rgba(#c62828, .2);
    color: var(--ion-color-danger)
}
.native-full-screen-header {
    padding-top: 50px !important;
}
.no-ripple {
    --ripple-color: transparent;
}
.link {
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	color: var(--ion-color-primary)  !important;
	&:hover {
		text-decoration: underline !important;
	}
}
.no-underline {
	text-decoration: none !important;
}
.no-word-wrap {
	white-space: nowrap
}
.divider {
    width: 100%;
    margin: 10px 0px;
    border-bottom: 1px solid $border-color;
}
.info {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    // text-align: center;
    color: #8c8c8c;
}
.comment-header {
    border-bottom: 1px solid #43b6d6;
    background-color: rgba(#43b6d6, 0.2) !important;
    padding: 10px 15px
}
.comment-body {

}
.comment-footer {

}
.comment-voter {
    border-top: 1px solid #43b6d6
}
// END CUSTOM CSS CLASSES



// FONT STYLES
.font-10 {
	font-size: 10px
}
.font-12 {
    font-size: 12px
}
.font-14 {
	font-size: 14px
}
.font-16 {
    font-size: 16px
}
.font-18 {
    font-size: 18px
}
.font-20 {
    font-size: 20px
}
.font-22 {
    font-size: 22px
}
.font-24 {
	font-size: 24px
} 
.italic {
	font-style: italic
}
.font-weight-200 {
    font-weight: 200 !important
}
.font-weight-300 {
    font-weight: 300 !important
}
.font-weight-400 {
    font-weight: 400 !important
}
.font-weight-500 {
    font-weight: 500 !important
}
.font-weight-600 {
    font-weight: 600 !important
}
.font-weight-700 {
    font-weight: 700 !important
}
// END FONT STYLES


// MENU ITEM CLASSES
.main-menu-item {
    font-weight: 500;
    &:hover {
        // background-color: rgba(73,108,137, 0.05) !important;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            height: 3px;
            right: 0;
            bottom: 0;
            background-color: var(--border-color);
        }
    }
}
.main-menu-item.width-100 {
    &:hover {
        background-color: rgba(#dee3ea, 0.05) !important;
        &::after {
            height: 0px
        }
    }
}
.active-main-menu-item {
    // background-color: rgba(73,108,137, 0.05) !important;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        height: 3px;
        right: 0;
        bottom: 0;
        background-color: var(--border-color);
    }
    &:hover {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            height: 3px;
            right: 0;
            bottom: 0;
            background-color: var(--ion-color-primary);
        }
    }
}
.active-main-menu-item.width-100 {
    background-color: rgba(#dee3ea, 0.05) !important;
    &::after {
        height: 0
    }
}

.child-menu-item {
    border-bottom: 1px solid var(--border-color);
    &:hover {
        background-color: rgba(#dee3ea, 0.05) !important;
    };
    &:last-child {
        border-bottom: none
    };
}
.active-child-menu-item {
    background-color: rgba(#dee3ea, 0.05) !important;
    &::after { 
        content: '';
        position: absolute;
        left: 0;
        width: 3px;
        top: 0;
        bottom: 0;
        background-color: var(--ion-color-primary)
    }
    &:first-child {
        &::after {
            border-top-left-radius: 12px
        }
    }
    &:last-child {
        &::after {
            border-bottom-left-radius: 12px
        }
    }
}
// END MENU ITEM CLASSES

// MARGIN CLASSES
.no-margin {
	margin: 0
}
.margin-top-4 {
	margin-top: 4px;
}
.margin-top-6 {
	margin-top: 6px;
}
.margin-top-8 {
	margin-top: 8px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-20 {
	margin-top: 20px;
}
.margin-top-30 {
	margin-top: 30px;
}
.margin-bottom-2 {
	margin-bottom: 2px;
}
.margin-bottom-4 {
	margin-bottom: 4px;
}
.margin-bottom-10 {
	margin-bottom: 10px;
}
.margin-bottom-20 {
	margin-bottom: 20px;
}
.margin-bottom-30 {
	margin-bottom: 30px;
}
.margin-left-5 {
    margin-left: 5px;
}
.margin-left-10 {
    margin-left: 10px;
}
.margin-left-15 {
    margin-left: 15px;
}
.margin-left-20 {
    margin-left: 20px;
}
.margin-right-5 {
    margin-right: 5px;
}
.margin-right-10 {
    margin-right: 10px;
}
.margin-right-15 {
    margin-right: 15px;
}
.margin-right-20 {
    margin-right: 20px;
}
// END MARGIN CLASSES



// PADDING CLASSES 
.no-padding {
	padding: 0;
}
.padding-5 {
	padding: 5px
}
.padding-8 {
	padding: 8px
}
.padding-10 {
	padding: 10px
}
.padding-15 {
	padding: 15px
}
.padding-20 {
	padding: 20px
}
.padding-left-20 {
    padding-left: 20px
}
.padding-right-2 {
    padding-right: 2px !important
}
.padding-right-4 {
    padding-right: 4px !important
}
.padding-right-6 {
    padding-right: 6px !important
}
.padding-right-20 {
    padding-right: 20px
}
.padding-x-large {
	padding: 50px
}
.padding-horizontal-10 {
	padding: 0 10px
}
.padding-horizontal-15 {
	padding: 0 15px
}
.padding-horizontal-20 {
	padding: 0 20px
}
.padding-top-5 {
    padding-top: 5px
}
.padding-top-10 {
    padding-top: 10px
}
// END PADDING CLASSES



// CUSTOM CHIPS
.chip-small {
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 20px;
    line-height: 1.5;
}
.chip-primary {
    background-color: rgba(#43b6d6, 1);
    color: white;
    border: 1px solid var(--ion-color-primary);
}
.chip-success {
    background-color: rgba(#2dd36f, 1);
    color: white;
    border: 1px solid var(--ion-color-success);
}
.chip-warn {
    background-color: rgba(#ffc409, 1);
    color: white;
    border: 1px solid var(--ion-color-warning);
}
.chip-danger {
    background-color: rgba(#c62828, 1);
    color: white;
    border: 1px solid var(--ion-color-danger);
}
.chip-suggestion {
    background-color: rgba(#ff6600, 1);
    color: white;
    border: 1px solid #ff6600;
}
.chip-note {
    background-color: rgba(117, 65, 26, 1);
    color: white;
    border: 1px solid rgb(117, 65, 26);
}
.chip-question {
    background-color: rgba(112, 87, 255, 1);
    color: white;
    border: 1px solid rgb(112, 87, 255);
}
// END CUSTOM CHIPS





// OVERLAYS
.overlay-left {
    &::after{
        position: absolute;
        content: ''
    }
}


// END OVERLAYS



// MODAL STYLES
.full-screen-modal {
    --min-width: 100%;
    --min-height: 100%;
}
// END MODAL STYLES



// MEDIA STYLES 
@media (max-width: 992px) {
    .grid-col-2 {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .grid-col-3 {
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    }
    .grid-col-4 {
         grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
	}
	.footer-text {
		font-size: 11px;
	}
}
@media (max-width: 767.98px) {
    .entity-flex-col-md-down {
        margin-top: 0px;
        padding: 0;
        display: flex !important;
        flex-direction: column !important;
	}
	.container-gap {
		gap: 15px;
	}
    .container-padding {
        padding: 10px !important;
    }
    .wrapper {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }
    .container-create {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
// END MEDIA STYLES